import React from 'react'
import buzzStyles from '../components/buzz.module.css'
import {Deutsch, English, LinkDE,LinkEN,LinkHOME} from '../components/linksetc.js'



class Buzzworder extends React.Component {
  
    constructor(props) {
      super(props);

      if (this.props.Language === "?en" ) {
        this.state = {business: "bakery" , product: 'bread', };
      }
      else {
        this.state = {business: "Bäckerei" , product: 'Brot', };
      }

      this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
      this.handleChangeProduct = this.handleChangeProduct.bind(this);
    }
  
    handleChangeBusiness(event) {
      this.setState({business: event.target.value});
    }
    handleChangeProduct(event) {
        this.setState({product: event.target.value });
    }



render() {
    return (
        
      <form>
        <div className={buzzStyles.buzz}>

        <English Language={this.props.Language}>
          your business: </English>

        <Deutsch Language={this.props.Language}>
Geschäft: </Deutsch>  <input id="buzzinput" type="text" value={this.state.business} maxLength="40" onChange={this.handleChangeBusiness} ref={(input) => { this.textInput = input; }}   />



          <p/>
        <Deutsch Language={this.props.Language}>Produkt: </Deutsch>
        <English Language={this.props.Language}>product: </English>

          <input class="buzzinput" type="text" value={this.state.product} maxLength="40" onChange={this.handleChangeProduct} ref={(input) => { this.textInput = input; }} id="text_field"   />
          </div>
        <p/>
        -----------<p/>

<English Language={this.props.Language}> 
We are not a {this.state.business}.  <p/>

We are a 360°-{this.state.business}. <p/>

We create individual {this.state.product} tailored to your needs.<p/>

We offer {this.state.product}-solutions.<p/>

We are a client-centric {this.state.business}.<p/>

{this.state.product} is our true passion. We think about nothing else, all the time.<p/>

We provide a {this.state.product}-ecosystem.<p/>

Our goal: to disrupt the {this.state.product}-industry.<p/>
Our {this.state.product} is always in sync with your strategy.<p/>

Our {this.state.product} is sustainable, organic and CO2-neutral.<p/>

We are the thought leaders in {this.state.product}.<p/>

Our {this.state.product} thinks outside the box.<p/>

Our {this.state.product} can now be experienced as a headless, cloud-based SaaS.<p/>

We are more than a {this.state.business}. We create unique {this.state.product}-experiences.<p/>

We are a lean {this.state.business} creating lean {this.state.product}.<p/>

We make {this.state.product} for humans.<p/>

Insights and learnings make our {this.state.product}.<p/>

Our {this.state.business} is all about people.<p/>

We use big data to improve your {this.state.product}-experience.<p/>

Our {this.state.product} is innovation-focused along the entire value-chain. Of {this.state.product}.<p/>

Our {this.state.product} is a total game-changer.<p/>

The future of {this.state.product} begins now.<p/>

We use deep learning to make our {this.state.product} data-driven.<p/>

We use artificial intelligence to enhance our {this.state.product}.<p/>

We offer holistic {this.state.product}.<p/>

Beyond {this.state.product}.<p/>

We are more than just a {this.state.business}. <p/>

Our {this.state.product} brings a true paradigm shift.<p/>

Fully leverage your {this.state.product}-assets.<p/>

At the end of the day, it's just {this.state.product}.<p/>

We take {this.state.product} to the next level.<p/>

Our mission is to inspire you with our {this.state.product}.<p/>

The world does not need another {this.state.business}. <p/>

More than {this.state.product}.<p/>

We are a sustainable {this.state.business}. Our {this.state.product} is sustainable. Did we mention how sustainable we are? <p/>

We are not a {this.state.business}. We are agents of change. <p/>

{this.state.product} for the world of tomorrow.<p/>


-----------





  <p/>
  <p/>
  You can also read everything <LinkDE location={this.props.Location}>in german</LinkDE> or <LinkHOME location={this.props.Location}>start over</LinkHOME>.




  </English>







  <Deutsch Language={this.props.Language}> 

Wir bieten {this.state.product}. Individuell und massgeschneidert.<p/>

{this.state.product}. Punkt. Nach. Jedem. Wort.<p/>

Wir sind eine kundenorientierte {this.state.business}.<p/>

{this.state.product} ist unsere Leidenschaft. Wir denken ununterbrochen daran.<p/>

Wir bieten ein {this.state.product}-Ökosystem.<p/>

Unser Ziel: Disruption der {this.state.product}-Industrie.<p/>

{this.state.product}, abgestimmt auf ihre Strategie.<p/>

{this.state.product}: nachhaltig, bio und CO2-neutral.<p/>

Wir sind keine {this.state.business}.  <p/>
Wir sind mehr als eine {this.state.business}. <p/>
Wir erschaffen einzigartige {this.state.product}-Erfahrungen.<p/>
Wir sind eine 360°-{this.state.business}. <p/>
20% Rabatt auf {this.state.product}! Profitieren Sie! Mehr erfahren! <p/>
{this.state.product}: jetzt auch als SaaS Cloud App.<p/>

Wir machen {this.state.product} für Menschen.<p/>

Insights und Learnings durch {this.state.product}.<p/>

Bei unserer {this.state.business} geht es um die Menschen.<p/>


{this.state.product} innovations-fokussiert entlang der ganzen Wertschöpfungskette.<p/>

{this.state.product} als Gamechanger.<p/>

Die Zukunft von {this.state.product} beginnt jetzt.<p/>

{this.state.product} basierend auf Big Data.<p/>

Mit künstlicher Intelligenz verbessern wir {this.state.product}.<p/>

Wir bieten ganzheitliche {this.state.product}-Lösungen.<p/>

{this.state.product}, {this.state.product}*innen und {this.state.product}:innen.<p/>

Wir sind mehr als eine {this.state.business}. <p/>

{this.state.product} bringt einen wahren Paradigmenwechsel.<p/>

Am Ende des Tages ist es einfach {this.state.product}.<p/>

{this.state.product} auf dem nächsten Level.<p/>

Unsere Mission ist, Menschen zu inspirieren mit {this.state.product}.<p/>

Die Welt braucht nicht noch eine {this.state.business}. <p/>

Mehr als {this.state.product}.<p/>

Wir sind eine nachhaltige {this.state.business}. Wir denken und handeln nachhaltig und können nicht aufhören, das Wort Nachhaltig zu sagen.<p/>

{this.state.product} für die Welt von morgen.<p/>

Eine {this.state.business}, die etwas bewegt.<p/>

{this.state.product} für den internationalen Tribe.<p/>

-----------

  <p/>
  <p/>
  Du kannst auch alles <LinkEN location={this.props.Location}>auf Englisch lesen</LinkEN> oder <LinkHOME location={this.props.Location}>zurück zum Anfang</LinkHOME>.


  </Deutsch>





      </form>
    );
  }
}

export default Buzzworder;