import React from 'react'
import {Deutsch, English, Type, LinkABOUT,LinkWORK, LinkHOME, LinkDE, LinkEN, Page, SiteTitle} from '../components/linksetc.js'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Buzzworder from '../components/buzzword_app'



const IndexPage = ({ location }) => (
    <Layout>

    <SiteTitle string="Corporate slogan generator" />
    <meta name="description" content="A buzzword template to generate your own corporate dadaism." />
    
    <Page location={location}>
  
        
    

        <Buzzworder Language={location.search} Location={location} />
  
    </Page>
    </Layout>
  
  )
  
  export default IndexPage